







import {Vue, Component, Prop} from 'vue-property-decorator';
import SummaryWidget from '@/app/modules/cart/components/partials/SummaryWidget.vue';
import CartCoreModel from '@/modules/carts/models/CartCoreModel';
import Cart from '@/app/lib/cart/Cart';

@Component({
  components: {
    SummaryWidget,
  },
})
export default class CartSummaryWidget extends Vue {
  /**
   * Getters
   */
  private get cart(): CartCoreModel {
    return Cart.model;
  }

  private get net(): string {
    return this.cart.totalsWithCurrencies.net;
  }

  private get gross(): string {
    return this.cart.totalsWithCurrencies.gross;
  }
}
