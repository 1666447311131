















import { Component, Vue } from 'vue-property-decorator';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import Cart from '@/app/modules/cart/components/Cart.vue';
import CartSummaryWidget from '@/app/modules/cart/components/partials/CartSummaryWidget.vue';
import CartLib from '@/app/lib/cart/Cart';

@Component({
  components: {
    CartSummaryWidget,
    Cart,
    MainHeader,
  },
})
export default class CartView extends Vue {
  /**
   * Display getters
   */
  private get displayCartSummaryWidget(): boolean {
    return !CartLib.isEmpty();
  }
}
